import React, { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Form from '../components/form';
import FormSent from '../components/form/FormSent';
import { ContentContext } from '../components/shared/ContentContext';

const Contact = ({ title, legend }) => {
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(null);
  return (
    <>
      <legend>{legend ?? title}</legend>
      { submitted
        ? <FormSent success={success} />
        : (
          <Form submitCallback={setSubmitted} successCallback={setSuccess}>
            <ContentContext.Consumer>
              {((content) => parse(content.Strings.FormContent.Description))}
            </ContentContext.Consumer>
          </Form>
        )}
    </>
  );
};

export default Contact;

Contact.defaultProps = { legend: null };
Contact.propTypes = { title: PropTypes.string.isRequired, legend: PropTypes.string };
