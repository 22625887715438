import PropTypes from 'prop-types';
import React from 'react';

const Menu = (props) => {
  const { active, id, title } = props;
  return (
    <li>
      <a
        className={`menu-item ${active ? 'active' : ''}`}
        id={`menu-${id}`}
        href={`#${id}`}
      >
        {title}
      </a>
    </li>
  );
};

Menu.defaultProps = { active: undefined };
Menu.propTypes = {
  active: PropTypes.bool,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Menu;
