import React from 'react';
import PropTypes from 'prop-types';
import Card from '../components/card';
import LoadMore from '../components/shared/LoadMore';
import { ContentContext } from '../components/shared/ContentContext';

class Skillset extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadMore: false,
    };
  }

  toggleLoadMore = () => {
    const { loadMore } = this.state;
    return this.setState({ loadMore: !loadMore });
  };

  render() {
    const { loadMore } = this.state;
    const { title, legend } = this.props;
    return (
      <>
        <legend>{legend ?? title}</legend>
        <ContentContext.Consumer>
          {((content) => {
            const skills = content.Skills;
            return (
              <>
                {skills.slice(0, loadMore ? skills.length : 5).map((item) => (
                  <Card imgSize={80} {...item} padded extraClasses={item.class} />
                ))}
                { skills.length > 5
                  && <LoadMore showMore={loadMore} callback={this.toggleLoadMore} />}
              </>
            );
          })}
        </ContentContext.Consumer>
      </>
    );
  }
}

export default Skillset;

Skillset.defaultProps = { legend: null };
Skillset.propTypes = { title: PropTypes.string.isRequired, legend: PropTypes.string };
