import api from '../api';
import { emailRegEx, nameRegEx, messageRegEx } from './regex';

let prevEmailValue = '';
let prevEmailValidity = false;
let prevTypo = '';

const validateWithAPI = (type, value) => {
  if (type === 'name') return { isValid: nameRegEx.test(value), typo: '' };
  if (type === 'message') return { isValid: messageRegEx.test(value), typo: '' };
  if (type === 'email') {
    if (!emailRegEx.test(value)) return { isValid: false, typo: '' };
    if (value === prevEmailValue) return { isValid: prevEmailValidity, typo: prevTypo };
    return api.get(`https://emailvalidation.abstractapi.com/v1/?api_key=${process.env.REACT_APP_EMAIL_API_KEY}&email=${value}`)
      .then((res) => {
        if (!res.ok) throw new Error(`Error with validation API request (${res.status})`);
        return res.json();
      })
      .then((data) => {
        const isValid = data.deliverability === 'DELIVERABLE' || data.quality_score > 0.5;
        prevEmailValidity = isValid;
        prevEmailValue = value;
        prevTypo = data.autocorrect;
        return { isValid, typo: data.autocorrect };
      })
      .catch((err) => {
        console.error(`Validation API failed: ${err}`);
        return { isValid: emailRegEx.test(value), typo: '' };
      });
  }

  return { isValid: true, typo: '' };
};

export default validateWithAPI;
