import React from 'react';
import MenuBar from '../menu';
import { Footer } from './Footer';
import { allPages } from '../../pages';

const DisplaySite = () => (
  <>
    <MenuBar />
    {allPages.map((item) => (
      <div key={item.key} className="content-div row" id={item.title.replace(' ', '')}>
        <item.element {...item} />
      </div>
    ))}
    <Footer />
  </>
);

export default DisplaySite;
