import React from 'react';
import PropTypes from 'prop-types';
import { ContentContext } from '../shared/ContentContext';

const FormSent = ({ success }) => (
  <ContentContext.Consumer>
    {(content) => (
      <div
        className={`alert alert-${success ? 'success' : 'danger'}`}
        dangerouslySetInnerHTML={{
          __html: success
            ? content.Strings.FormContent.Messages.Success
            : content.Strings.FormContent.Messages.Error,
        }}
      />
    )}
  </ContentContext.Consumer>
);

FormSent.propTypes = { success: PropTypes.bool.isRequired };

export default FormSent;
