import React from 'react';
import PropTypes from 'prop-types';

const LoadMore = ({ showMore, callback }) => (
  <div className="col-md-4 card-container">
    <div className="show-more-container">
      <button type="button" className="show-more arrow themable-background" onClick={callback}>
        <span>
          {showMore ? '<' : '>'}
        </span>
      </button>
      <button type="button" className="show-more themable-background themable-text" onClick={callback}>{showMore ? 'Show Less' : 'Show More'}</button>
    </div>
  </div>
);

LoadMore.propTypes = { showMore: PropTypes.bool.isRequired, callback: PropTypes.func.isRequired };

export default LoadMore;
