import React from 'react';
import PropTypes from 'prop-types';

const WrapIf = ({
  condition,
  With,
  children,
  ...props
}) => (condition
  ? <With {...props}>{children}</With>
  : children);

export default WrapIf;

WrapIf.defaultProps = {
  condition: null,
};

WrapIf.propTypes = {
  condition: PropTypes.string,
  With: PropTypes.string.isRequired,
  children: PropTypes.shape([]).isRequired,
};
