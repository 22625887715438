import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({ url, linkText }) => (
  <a rel="noopener noreferrer" {...url && { href: url }} target="_blank" className="external-link">
    <small className="text-muted themable-text">{`${linkText} `}
      <img className="new-tab-icon" src="./static/new_tab.png" alt="New Tab" />
    </small>
  </a>
);

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

export default ExternalLink;
