import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '../components/card';
import LoadMore from '../components/shared/LoadMore';
import { ContentContext } from '../components/shared/ContentContext';

const Projects = ({ title, legend }) => {
  const [loadMore, setLoadMore] = useState(false);
  return (
    <>
      <legend>{legend ?? title}</legend>
      <ContentContext.Consumer>
        {((content) => {
          const projects = content.Projects;
          return (
            <>
              {projects.slice(0, loadMore ? projects.length : 5).map((item) => (
                <Card key={item.name} {...item} />
              ))}
              { projects.length > 5
                && <LoadMore showMore={loadMore} callback={() => setLoadMore(!loadMore)} />}
            </>
          );
        })}
      </ContentContext.Consumer>
    </>
  );
};

export default Projects;

Projects.defaultProps = { legend: null };
Projects.propTypes = { title: PropTypes.string.isRequired, legend: PropTypes.string };
