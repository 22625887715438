import React, { useState, useEffect } from 'react';
import { Cookies } from 'react-cookie';
import toggleTheme from './handlers/toggleTheme';
import Loader from './components/shared/Loader';
import DisplaySite from './components/shared/DisplaySite';
import { ContentContext } from './components/shared/ContentContext';
import StaticContent from '../StaticContent.json';

const jsonURL = process.env.NODE_ENV === 'production' ? 'https://api.npoint.io/5a11100234c57630b5d0' : '';

const App = (props) => {
  const cookie = new Cookies();
  const [jsonData, setData] = useState();
  useEffect(() => {
    fetch(jsonURL)
      .then((res) => res.json())
      .then((data) => setData(data))
      .catch(setData(StaticContent));

    setTimeout(() => {
      if (cookie.get('darkTheme') === 'true') {
        toggleTheme();
      }
    });
  }, []);

  return (jsonData
    ? (
      <ContentContext.Provider value={jsonData}>
        <DisplaySite {...props} />
      </ContentContext.Provider>
    )
    : <Loader />
  );
};

export default App;
