import Contact from './Contact';
import AboutMe from './AboutMe';
import Skillset from './Skillset';
import Projects from './Projects';

const allPages = [
  {
    key: 0,
    element: AboutMe,
    title: 'About Me',
  },
  {
    key: 1,
    element: Projects,
    title: 'Projects',
    legend: 'Explore My Projects',
  },
  {
    key: 2,
    element: Skillset,
    hideFromNavbar: true,
    title: 'My Skillset',
  },
  {
    key: 3,
    element: Contact,
    title: 'Contact',
    legend: 'Get in touch!',
  },
];

export { allPages };
