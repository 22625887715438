import { Cookies } from 'react-cookie';

let toggle = false;

const toggleTheme = () => {
  toggle = !toggle;
  const cookie = new Cookies();
  cookie.set('darkTheme', toggle, { expires: new Date(new Date().setDate(new Date().getDate() + 30)) });
  document.querySelectorAll('.themable-text').forEach((e) => e.classList.toggle('dark-mode-text'));
  document.querySelectorAll('.themable-background').forEach((e) => e.classList.toggle('dark-mode-background'));
};

export default toggleTheme;
