const headers = () => {
  const h = new window.Headers();
  h.append('Content-Type', 'application/json');
  return h;
};

const request = (method, path, body) => {
  const url = `${path}`;
  const options = { method, headers: headers() };

  if (body) {
    options.body = JSON.stringify(body);
  }

  return window.fetch(new window.Request(url, options));
};

const api = {
  get(path) {
    return request('GET', path);
  },
  post(path, data = {}) {
    return request('POST', path, data);
  },
  delete(path) {
    return request('DELETE', path);
  },
};

export default api;
