import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { ContentContext } from '../components/shared/ContentContext';

const AboutMe = ({ title, legend }) => (
  <ContentContext.Consumer>
    {(data) => {
      const content = data.Strings.AboutMe;
      return (
        <>
          <legend>{legend ?? title}</legend>
          <div className="avatar-container">
            <p className="avatar-text">
              {parse(content.Body)}
            </p>
          </div>
        </>
      );
    }}
  </ContentContext.Consumer>
);

export default AboutMe;

AboutMe.defaultProps = { legend: null };
AboutMe.propTypes = { title: PropTypes.string.isRequired, legend: PropTypes.string };
