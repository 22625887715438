import React from 'react';
import PropTypes from 'prop-types';
import encode from '../../handlers/handleEncode';
import { Label, Button } from './Fields';
import { ContentContext } from '../shared/ContentContext';
import validateWithAPI from '../../validation/validateWithAPI';
import validateContact from '../../validation/schema/contact';

class Form extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: '',
        email: '',
        message: '',
      },
      typo: '',
      formValid: false,
      isSubmitting: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { successCallback, submitCallback } = this.props;
    const { values, formValid } = this.state;
    if (!formValid) return;

    this.setState({ isSubmitting: true });
    fetch('/', {
      method: 'POST',
      action: successCallback(true),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact-form', ...values }),
    }).then((res) => successCallback(res.status === 200))
      .catch(() => {
        successCallback(false);
        submitCallback(true);
      }).finally(() => submitCallback(true));
  };

  handleBlur = async (e) => {
    await this.validateField(e.target);
  };

  validateField = async (e, isTypoCorrection = false) => {
    this.setState({ formValid: false });
    const validation = isTypoCorrection ? { isValid: true, typo: '' } : await validateWithAPI(e.name, e.value);
    this.setState({ typo: validation.typo ?? '', formValid: validation.isValid });
    e.setCustomValidity(validation.isValid ? '' : validateContact(e.name));
  };

  handleChange = (e) => {
    this.setState((prev) => ({ values: { ...prev.values, [e.target.name]: e.target.value } }));
  };

  fixTypo = async (name, typo) => {
    const element = document.getElementById(name);
    element.value = typo;
    this.setState((prev) => ({ values: { ...prev.values, [element.name]: typo } }));
    await this.validateField(element, true);
  };

  render() {
    const { values, isSubmitting, typo } = this.state;
    const { children } = this.props;
    return (
      <ContentContext.Consumer>
        {(data) => {
          const content = data.Strings.FormContent;
          return (
            <form name="contact-form" onSubmit={this.handleSubmit}>
              {children}
              <fieldset>
                <Label id="txtName" label={content.Labels.Name} />
                <input id="txtName" className="form-control" type="text" placeholder={content.Placeholders.Name} name="name" value={values.name} onBlur={this.handleBlur} onChange={this.handleChange} required />
              </fieldset>
              <fieldset>
                <Label id="txtEmail" label={content.Labels.Email} />
                <input id="txtEmail" className="form-control" type="email" placeholder={content.Placeholders.Email} name="email" value={values.email} onBlur={this.handleBlur} onChange={this.handleChange} required />
              </fieldset>
              {typo !== '' && (
                <p>
                  Did you mean:
                  <button
                    className="btn btn--inline-link"
                    type="button"
                    onClick={() => this.fixTypo('txtEmail', typo)}
                    onKeyDown={() => this.fixTypo('txtEmail', typo)}
                  >
                    {typo}
                  </button>
                  ?
                </p>
              )}
              <fieldset>
                <Label id="txtMessage" label={content.Labels.Message} />
                <textarea id="txtMessage" className="form-control" maxLength={400} placeholder={content.Placeholders.Message} name="message" value={values.message} onBlur={this.handleBlur} onChange={this.handleChange} required />
              </fieldset>
              <Button text={content.ButtonText} disabled={isSubmitting} />
            </form>
          );
        }}
      </ContentContext.Consumer>
    );
  }
}

Form.propTypes = {
  successCallback: PropTypes.func.isRequired,
  submitCallback: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Form;
